<template>
  <div>
    <div class="statTitle">
      <h1>{{ list.title }}</h1>
      <div class="Dl">
        <dl>
          <dt>回收量</dt>
          <dd>{{ list.recycled }}</dd>
        </dl>
        <dl>
          <dt>浏览量</dt>
          <dd>{{ list.views }}</dd>
        </dl>
        <dl>
          <dt>回收率</dt>
          <dd>{{ list.recoveryRate }}</dd>
        </dl>
        <dl>
          <dt>平均完成时间</dt>
          <dd>{{ list.avgTime }}</dd>
        </dl>
      </div>
    </div>
    <div class="content">
      <div>
        <el-form ref="form" class="form" :model="form" label-width="80px">
          <el-form-item label="提交时间">
            <el-date-picker
              v-model="form.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="来源">
            <el-select v-model="form.source" placeholder="请选择" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索">
            <el-input v-model="form.search" placeholder="搜索" clearable></el-input>
          </el-form-item>
          <el-form-item label="评分人">
            <el-select
              v-model="form.rater"
              placeholder="请选择"
              @change="changeRater"
              clearable
            >
              <el-option
                v-for="item in scorerList"
                :key="item.username"
                :label="item.nickname"
                :value="item.username"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="被评分人">
            <el-select v-model="form.toRater" placeholder="请选择" clearable>
              <el-option
                v-for="item in markScorerList"
                :key="item.username"
                :label="item.nickname"
                :value="item.username"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="reset">
        <div class="icoReset">
          <!-- 题目筛选
          <i class="el-icon-circle-plus-outline"></i> -->
        </div>
        <div class="con">
          <div class="conReset" @click="search">搜索</div>
          <div class="conReset" @click="reset">重置</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import apis from "@/apis";
export default {
  name: "title",
  props: ["list"],
  data() {
    return {
      form: {
        time: "",
        source: "",
        search: "",
        rater: "",
        toRater: "",
      },
      options: [
        {
          value: "1",
          label: "内部人员",
        },
        {
          value: "2",
          label: "外部人员",
        },
      ],
      scorerList: [],
      markScorerList: [],
    };
  },
  mounted() {
    this.getQueryUsers(this.$route.query.id);
    this.getQueryUsersRa(this.$route.query.id,'')
  },
  methods: {
    search() {
      const startDate = this.form.time[0]
        ? moment(this.form.time[0]).format("YYYY-MM-DD")
        : "";
      const endDate = this.form.time[1]
        ? moment(this.form.time[1]).format("YYYY-MM-DD")
        : "";
      const baseinfo = {
        ...this.form,
        startDate,
        endDate,
      };
      this.$emit("search", baseinfo);
    },
    reset() {
      this.form = {
        time: "",
        source: "",
        search: "",
        rater: "",
        toRater: "",
      };

      this.search();
    },
    getQueryUsers(id) {
      apis
        .subjectQueryUsers({
          questionnaireId: id,
          type: 2,
          reply: true,
        })
        .then(({ data }) => {
          this.scorerList = data;
        });
    },
    changeRater() {
      this.getQueryUsersRa(this.$route.query.id, this.form.rater);
      this.form.toRater =''
    },
    getQueryUsersRa(id, rater) {
      apis
        .subjectQueryUsers({
          questionnaireId: id,
          type: 3,
          reply: true,
          rater: rater,
        })
        .then(({ data }) => (this.markScorerList = data));
    },
  },
};
</script>

<style lang="scss" scoped>
.statTitle {
  display: flex;
  justify-content: space-between;
  .Dl {
    display: flex;
    dl {
      margin-left: 30px;
      dt {
        font-size: 10px;
      }
      dd {
        font-size: 14px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.content {
  background: #fff;
  margin-top: 10px;
  border-radius: 5px;
  padding: 20px 15px 15px 15px;
}
.form {
  display: flex;
  flex-wrap: wrap;
  ::v-deep .el-input {
    width: 1.5rem;
  }
  ::v-deep .el-date-editor--daterange.el-input__inner {
    width: 2.5rem;
  }
  ::v-deep .el-form-item {
    margin-right: 50px;
  }
}
.con {
  display: flex;
}
.reset {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  padding-left: 12px;
  .icoReset {
    cursor: pointer;
  }
  .conReset {
    color: #008755;
    cursor: pointer;
    margin-left: 20px;
  }
}
</style>
