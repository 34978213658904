<template>
  <div>
    <Title :list="list" @search="search"></Title>
    <Content :conList="list" :tableList="table" :rateTable="rateTable"></Content>
    <!-- <Footer :list="table"></Footer> -->
  </div>
</template>

<script>
import Title from "../component/title.vue";
import Content from "../component/content.vue";
// import Footer from "../component/footer.vue";
import apis from "@/apis";
export default {
  name: "report",
  components: {
    Title,
    Content,
    // Footer,
  },
  data() {
    return {
      list: {},
      baseinfo: {
        startDate: "",
        endDate: "",
        source: "",
        search: "",
      },
      table: [],
      rateTable:[],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    search(value) {
      this.baseinfo = {
        startDate: value.startDate,
        endDate: value.endDate,
        source: value.source,
        search: value.search,
        rater: value.rater,
        toRater: value.toRater,
      };
      this.getList();
    },
    getList() {
      apis
        .questionnaireStatistical({
          id: this.$route.query.id,
          startDate: this.baseinfo.startDate,
          endDate: this.baseinfo.endDate,
          source: this.baseinfo.source,
          search: this.baseinfo.search,
          rater: this.baseinfo.rater,
          toRater: this.baseinfo.toRater,
        })
        .then((data) => {
          if (data) {
            console.log(data);
            this.list = data.data;
            this.table = data.data.texts;
            this.rateTable = data.data.rateUsers;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 10px;
  background: #fff;
  border-radius: 5px;
  padding: 10px 15px 15px 15px;
  .tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ::v-deep .el-tabs__nav {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #fff;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: #fff;
    }
    ::v-deep .el-tabs__item {
      font-size: 16px;
    }
  }
}
.firstTop {
  font-size: 10px;
  line-height: 26px;
  color: #3d3d3d;
}
.firstNum {
  font-size: 10px;
  color: #008755;
  display: flex;
  justify-content: flex-end;
  span {
    cursor: pointer;
  }
}
.table {
  width: 100%;
  margin-top: 15px;
  ::v-deep .cell {
    display: flex;
    justify-content: center;
  }
  ::v-deep .el-table_1_column_1 {
    display: flex;
    justify-content: flex-start;
  }
}
.firstCharts {
  margin-top: 15px;
  ::v-deep .el-tabs__nav {
    width: 270px !important;
  }
  ::v-deep .el-tabs__header {
    width: 270px !important;
  }
  ::v-deep .el-tabs__item.is-active {
    background: #008755;
    color: #fff;
  }
}
.score {
  background: #f5fff7;
  border-left: #008755 2px solid;
  font-size: 10px;
  padding: 10px 15px;
}
</style>
